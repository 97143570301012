<template>
  <Main />
</template>

<script>
import Main from "../components/Main.vue";

export default {
  name: "Home",
  components: {
    Main,
  },
};
</script>
