import { mapGetters } from "vuex";
import i18n from "../i18n";

export default {
  computed: {
    ...mapGetters("auth", { loggedUser: "sessionUser" }),
  },
  methods: {
    getEnumText(array, item) {
      const result = array.find((x) => x.value === item);
      if (result) {
        return result.text;
      }
      return "";
    },
    getMessageText(message) {
      if (!message) {
        return "";
      }
      const [error, item] = message.split("-");
      if (item) {
        return i18n.t(error, { item: i18n.t(`lbl.${item}`) });
      }
      return i18n.t(message);
    },
    getItem(items, itemId) {
      const item = items.find((x) => x.id === itemId);
      return item || {};
    },
    getFilters(options, filter) {
      const {
        sortBy: sortByArray,
        sortDesc: sortDescArray,
        mustSort,
        multiSort,
        itemsPerPage: limit,
        ...params
      } = options;
      let sortBy;
      if (sortByArray.length) {
        const sort = [];
        for (let i = 0; i < sortByArray.length; i += 1) {
          sort.push(`${sortByArray[i]}:${sortDescArray[i] ? "desc" : "asc"}`);
        }
        sortBy = sort.join(",");
      }
      const filters = {
        sortBy,
        limit,
        ...params,
      };
      Object.assign(filters, filter);
      return filters;
    },
    sessionUser() {
      const user = this.loggedUser();
      return user;
    },
    auth(right) {
      if (!right) {
        return true;
      }
      const user = this.loggedUser();
      if (!user) {
        return false;
      }
      const result = Array.isArray(right)
        ? user.rights.some((el) => right.includes(el))
        : user.rights.includes(right);
      return result;
    },
  },
};
