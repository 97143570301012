<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs12
      sm8
      md6
      lg4
    >
      <v-card class="elevation-12 mt-16">
        <v-toolbar
          dark
          color="primary"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title>
            {{ edit ? $t("lbl.updateProfile") : $t("lbl.resigterUser") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="user.email"
              :rules="rules.emailRules"
              :label="$t('lbl.email')"
            ></v-text-field>

            <template v-if="!edit">
              <v-text-field
                v-model="user.password"
                :type="'password'"
                :rules="rules.passwordRules"
                :label="$t('lbl.password')"
              ></v-text-field>
            </template>
            <template v-else>
              <v-text-field
                v-model="user.password"
                :disabled="locked"
                :type="'password'"
                :rules="!locked ? rules.passwordRules : []"
                :label="$t('lbl.password')"
              >
                <v-icon
                  slot="append-outer"
                  @click="locked = !locked"
                  color="red"
                  class="lock-button"
                >
                  mdi-lock
                </v-icon>
              </v-text-field>
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            class="mr-2"
            @click="reset"
          >{{ $t("lbl.clear") }}</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            :disabled="!valid"
            color="primary"
            @click="save"
          >
            {{ edit ? $t("lbl.update") : $t("lbl.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import {
  required, email, minLength, password,
} from "@/common/validation";

export default {
  data: () => ({
    edit: false,
    valid: true,
    user: {
      email: "",
      password: "",
    },
    rules: {
      emailRules: [required, email],
      passwordRules: [required, minLength(8), password],
    },
    locked: true,
  }),
  methods: {
    ...mapActions("user", ["getProfile"]),
    ...mapActions("auth", ["register", "profile"]),
    reset() {
      this.$refs.form.reset();
    },
    save() {
      const user = { ...this.user };
      if (this.edit && this.locked) {
        delete user.password;
      }
      const request = this.edit ? this.profile(user) : this.register(user);
      request
        .then(() => {
          this.$toasted.success(
            this.edit
              ? this.$t("msg.update", { item: this.$t("lbl.profile") })
              : this.$t("msg.save", { item: this.$t("lbl.user") }),
          );
          this.$router.push("/");
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    },
  },
  created() {
    const sessionUser = this.sessionUser();
    if (sessionUser) {
      this.edit = true;
      this.getProfile()
        .then((user) => {
          Object.assign(this.user, {
            email: user.email,
            password: user.password,
          });
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        });
    }
  },
};
</script>

<style scoped>
.lock-button {
  pointer-events: auto;
}

.v-toolbar__title {
  font-size: 1.5rem !important;
}
</style>
