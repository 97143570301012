const USER_SESSION = "s:user";
const ACCESS_TOKEN = "a:token";
const REFRESH_TOKEN = "r:token";

function setTokens(tokens) {
  localStorage.setItem(ACCESS_TOKEN, tokens.access.token);
  localStorage.setItem(REFRESH_TOKEN, tokens.refresh.token);
}

function removeTokens() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

function setUser(user) {
  localStorage.setItem(USER_SESSION, JSON.stringify(user));
}

export default {
  getUser() {
    const user = localStorage.getItem(USER_SESSION);
    if (user) {
      try {
        return JSON.parse(user);
      } catch {
        return null;
      }
    }
    return null;
  },

  getAccessToken() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return accessToken || "";
  },

  getRefreshToken() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    return refreshToken || "";
  },

  updateTokens(tokens) {
    setTokens(tokens);
  },

  deleteTokens() {
    removeTokens();
  },

  saveSession(data) {
    setUser(data.user);
    setTokens(data.tokens);
  },

  updateSession(data) {
    const user = this.getUser();
    if (user) {
      user.email = data.email;
      setUser(user);
    }
  },

  deleteSession() {
    localStorage.removeItem(USER_SESSION);
    removeTokens();
  },
};
