<template>
  <v-app>
    <h1 v-if="error.statusCode === 404">
      {{ pageNotFound }}
    </h1>
    <h1 v-else>
      {{ otherError }}
    </h1>
    <router-link to="/">
      {{ $t("nav.home") }}
    </router-link>
  </v-app>
</template>

<script>
export default {
  layout: "empty",
  props: {
    error: {
      type: Object,
      default() {
        return { statusCode: 404 };
      },
    },
  },
  data() {
    return {
      pageNotFound: this.$t("error.pageNotFound"),
      otherError: this.$t("error.otherError"),
    };
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
