import { AuthService } from "@/common/api.service";
import JwtService from "@/common/jwt.service";

const initialState = {};

const state = { ...initialState };

const getters = {
  sessionUser: () => JwtService.getUser,
  refreshToken: () => JwtService.getRefreshToken,
};

const actions = {
  register(context, credentials) {
    return AuthService.register(credentials)
      .then(({ data }) => {
        context.commit("saveSession", data);
        return data;
      });
  },
  login(context, credentials) {
    return AuthService.login(credentials)
      .then(({ data }) => {
        context.commit("saveSession", data);
        return data;
      });
  },
  logout(context) {
    return AuthService.logout({ refreshToken: context.getters.refreshToken() })
      .then(() => {}).catch(() => {})
      .finally(() => {
        context.commit("deleteSession");
      });
  },
  forgotPassword(_, body) {
    return AuthService.forgotPassword(body).then(({ data }) => data);
  },
  resetPassword(_, { token, ...body }) {
    return AuthService.resetPassword({ token }, body).then(({ data }) => data);
  },
  sendVerificationEmail(_, body) {
    return AuthService.sendVerificationEmail(body).then(({ data }) => data);
  },
  verifyEmail(_, body) {
    return AuthService.verifyEmail(body).then(({ data }) => data);
  },
  profile(context, credentials) {
    return context.dispatch("user/updateProfile", credentials, { root: true })
      .then((data) => {
        context.commit("updateSession", data);
        return data;
      });
  },
};

const mutations = {
  saveSession(_, data) {
    JwtService.saveSession(data);
  },
  updateSession(_, data) {
    JwtService.updateSession(data);
  },
  deleteSession() {
    JwtService.deleteSession();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
