<template>
  <div>
    <UserForm />
  </div>
</template>
<script>
import UserForm from "@/components/UserForm.vue";

export default {
  components: {
    UserForm,
  },
};
</script>
