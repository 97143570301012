<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const routeTitle = to.matched
          .filter((x) => x.meta.title)
          .map((x) => this.$t(x.meta.title))
          .join(" - ");
        this.$store.commit("setTitle", routeTitle || this.$t("lbl.company"));
      },
    },
  },
};
</script>
