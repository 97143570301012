import { DateTime, Settings } from "luxon";
import { locale } from "./config";

Settings.defaultLocale = locale;

export default function install(Vue) {
  Object.defineProperties(Vue.prototype, {
    $luxon: {
      get() {
        return DateTime;
      },
    },
  });
}
