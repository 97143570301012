<template>
  <v-data-table
    :headers="headers"
    :items="transactions"
    :items-per-page="100"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
      itemsPerPageOptions: [10, 25, 50, 100],
    }"
    :hide-default-footer="true"
    :loading="loading"
    multi-sort
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>{{ $t("lbl.transactions") }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-chip
          label
          outlined
          color="green"
        >
          {{ $t("lbl.total") }} <v-icon small> mdi-currency-try </v-icon> &nbsp;&nbsp;
          <span class="green--text">{{ credit }}</span>
          &nbsp;/&nbsp;
          <span class="orange--text">{{ creditAll }}</span>
        </v-chip>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          :max-width="editedIndex === -1 ? '850px' : '450px'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="auth('createTransaction')"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="getIbanCode"
            >{{
              $t("lbl.newPayment")
            }}</v-btn>
          </template>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pa-0"
                      v-if="auth('createTransaction')"
                    >
                    <v-card-title class="d-flex justify-center pa-0">
                      {{ $t("lbl.contractTitle") }}                      
                    </v-card-title>
                    <span>{{ $t("lbl.contract1") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract11") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract2") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract21") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract3") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract31") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract4") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract41") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract5") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract51") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract6") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract61") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract7") }}</span>
                    <br>
                    <span>{{ $t("lbl.contract71") }}</span>
                    <br>
                    <v-checkbox v-model="chboxAccept" hide-details>
                      <template v-slot:label>
                        <div class="info--text">
                          {{ $t("lbl.contractAccept")}}
                        </div>
                      </template>
                    </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-text class="pa-0">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pa-0"
                      v-if="auth('createTransaction')"
                    >
                      <v-card-title class="d-flex justify-center pa-0">
                        <span class="subtitle-1">{{ $t("lbl.iban1") }}</span>
                      </v-card-title>
                      <v-card-title class="d-flex justify-center pa-0">
                        <span class="subtitle-1">{{ $t("lbl.iban2") }}</span>
                      </v-card-title>
                      <v-card-title class="d-flex justify-center pa-0">
                        <span class="subtitle-1">{{ $t("lbl.iban3") }}</span>
                      </v-card-title>
                      <v-card-title class="d-flex justify-center pa-0">
                        <span class="subtitle-1 green--text">{{ editedItem.code }}</span>
                      </v-card-title>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="auth('createTransaction')"
                    >
                      <v-text-field
                        outlined
                        hide-details
                        v-model="editedItem.amount"
                        :rules="rules.required"
                        :label="$t('lbl.amount')"
                      ></v-text-field>
                      <!-- hidden input field added due to form submit bug with only one input -->
                      <!-- https://github.com/vuetifyjs/vuetify/issues/3032 -->
                      <!-- https://github.com/vuetifyjs/vuetify/issues/4302 -->
                      <v-text-field v-show="false"></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="auth('manageTransactions')"
                    >
                      <v-select
                        v-model="editedItem.status"
                        :items="trxStatus"
                        :label="$t('lbl.status')"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="reset"
                >{{ $t("lbl.clear") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >{{
                  $t("lbl.cancel")
                }}</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="!valid || (editedIndex === -1 && !chboxAccept)"
                  @click="save"
                >{{
                  $t("lbl.save")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="1"
          >
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filter.amount"
              :label="$t('lbl.amount')"
              @keydown.enter="initialize(true)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="1"
          >
            <v-text-field
              outlined
              dense
              hide-details
              v-model="filter.code"
              :label="$t('lbl.code')"
              @keydown.enter="initialize(true)"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="auth('getUsers')"
            cols="12"
            sm="6"
            md="2"
          >
            <v-autocomplete
              outlined
              dense
              hide-details
              clearable
              v-model="filter.user"
              :items="users"
              item-text="email"
              item-value="id"
              :label="$t('lbl.user')"
              :disabled="disable"
              @change="initialize(true)"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="1"
          >
            <v-select
              outlined
              dense
              hide-details
              hidden
              clearable
              v-model="filter.type"
              :items="trxTypes"
              :label="$t('lbl.type')"
              @change="initialize(true)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="1"
          >
            <v-select
              outlined
              dense
              hide-details
              hidden
              clearable
              v-model="filter.status"
              :items="trxStatus"
              :label="$t('lbl.status')"
              @change="initialize(true)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-datetime-picker
              v-model="filter.start"
              :label="$t('lbl.start')"
              :clearText="$t('lbl.clear')"
              :okText="$t('lbl.ok')"
              :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
              :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
              :timePickerProps="{ format: '24hr', 'no-title': true }"
              @input="initialize(true)"
            ></v-datetime-picker>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-datetime-picker
              v-model="filter.end"
              :label="$t('lbl.end')"
              :clearText="$t('lbl.clear')"
              :okText="$t('lbl.ok')"
              :textFieldProps="{
                outlined: true,
                dense: true,
                hideDetails: true,
                clearable: true,
              }"
              :datePickerProps="{ locale: $i18n.locale, 'no-title': true }"
              :timePickerProps="{ format: '24hr', 'no-title': true }"
              @input="initialize(true)"
            ></v-datetime-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            :sm="auth('getUsers') ? '6' : '12'"
            md="1"
          >
            <v-btn
              block
              color="warning"
              @click="refresh"
            >
              <v-icon>mdi-refresh-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <span :class=getTrxTypeColor(item)>{{ getEnumText(trxTypes, item.type) }}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <span :class=getTrxStatusColor(item)>{{ getEnumText(trxStatus, item.status) }}</span>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      <span>
        {{ item.date && $luxon.fromISO(item.date).toLocaleString(fmt) }}
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        class="mr-2"
        :disabled="disable"
        @click="editItem(item)"
      > mdi-pencil </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { required } from "@/common/validation";
import { trxTypes, trxStatus } from "@/common/enums";
import { generateId } from "@/common/utils";

export default {
  data: () => ({
    chboxAccept:false,
    dialog: false,
    disable: false,
    valid: true,
    loading: true,
    options: {},
    total: 0,
    transactions: [],
    users: [],
    credit: 0,
    creditAll: 0,
    fmt: DateTime.DATETIME_SHORT,
    editedIndex: -1,
    editedItem: {
      amount: "",
    },
    defaultItem: {
      amount: "",
    },
    rules: {
      required: [required],
    },
    filter: {
      amount: null,
      code: null,
      user: null,
      start: null,
      end: null,
      type: null,
      status: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("lbl.newPayment")
        : this.$t("lbl.editPayment");
    },
    headers() {
      const columns = [];
      columns.push(
        { text: this.$t("lbl.no"), value: "no" },
        { text: this.$t("lbl.amount"), value: "amount" },
      );
      if (this.auth("getUsers")) {
        columns.push({ text: this.$t("lbl.user"), value: "user.email" });
      }
      columns.push(
        { text: this.$t("lbl.type"), value: "type" },
        { text: this.$t("lbl.status"), value: "status" },
        { text: this.$t("lbl.project"), value: "project.name" },
        { text: this.$t("lbl.takbisId"), value: "project.takbisId" },
        { text: this.$t("lbl.code"), value: "code" },
        { text: this.$t("lbl.date"), value: "date" },
      );
      if (this.auth("manageTransactions")) {
        columns.push({
          text: this.$t("lbl.actions"),
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return columns;
    },
    trxTypes() {
      return trxTypes();
    },
    trxStatus() {
      return trxStatus();
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("transaction", [
      "findTransactions",
      "getTransaction",
      "saveTransaction",
    ]),
    ...mapActions("user", ["findUsers"]),

    async initialize(search) {
      this.loading = true;
      if (search) {
        this.options.page = 1;
      }
      const filters = this.getFilters(this.options, this.filter);
      try {
        const { results, totalResults } = await this.findTransactions(filters);
        this.transactions = results;
        this.total = totalResults;
        this.credit = this.getTotal(
          results.filter((item) => item.status === "approved"),
        );
        this.creditAll = this.getTotal(results);
      } catch (error) {
        this.$toasted.error(this.getMessageText(error.message));
      } finally {
        this.loading = false;
      }

      if (this.auth("getUsers")) {
        try {
          const { results } = await this.findUsers({
            limit: 0,
          });
          this.users = results;
        } catch (error) {
          this.$toasted.error(this.getMessageText(error.message));
        }
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.editedIndex = this.transactions.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    save() {
      this.dialog = false;
      this.disable = true;
      const editedItem = { ...this.editedItem };
      return this.saveTransaction(editedItem)
        .then(() => {
          this.initialize();
          this.$toasted.success(
            this.$t("msg.save", { item: this.$t("lbl.transaction") }),
          );
        })
        .catch((error) => {
          this.$toasted.error(this.getMessageText(error.message));
        })
        .finally(() => {
          this.disable = false;
        });
    },

    refresh() {
      Object.keys(this.filter).forEach((i) => {
        this.filter[i] = null;
      });
      this.initialize();
    },

    getIbanCode() {
      const code = generateId(6).toUpperCase();
      this.editedItem.code = code;
      return code;
    },

    getTrxTypeColor(item) {
      const { type, status } = item;
      if (type === "debit") {
        return "red--text";
      }
      if (type === "credit" && status === "submitted") {
        return "orange--text";
      }
      if (type === "credit" && status === "approved") {
        return "green--text";
      }
      return "";
    },

    getTrxStatusColor(item) {
      const { status } = item;
      switch (status) {
        case "submitted":
          return "orange--text";
        case "approved":
          return "green--text";
        default:
          return "";
      }
    },

    getTotal(items) {
      return items.reduce((acc, item) => acc + item.amount, 0);
    },
  },
};
</script>

<style>
.swal2-html-container {
  font-size: 26px !important;
}
.swal2-checkbox,
.swal2-radio {
  font-size: 22px;
  color: red !important;
}
</style>
